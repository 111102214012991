.projects {
    &__content {
        padding: 120px 0;
        h2 {
            margin-bottom: 30px; }
        p {
            margin-bottom: 30px; } }
    button {
        min-height: 50px;
        padding-left: 30px;
        padding-right: 30px;
        border-radius: 15px;
        font-weight: 600; }
    &-card {
        position: relative;
        width: 100%;
        padding-top: 100%;
        margin-bottom: 30px;
        border-radius: 30px;
        border: 1px solid rgba(0,0,0,0.1);
        overflow: hidden;
        &__inner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #fff;
            padding: 30px;
            display: flex;
            align-items: center;
            justify-content: center; } } }
