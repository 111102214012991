.comments {
    h2 {
        margin-bottom: 60px; }
    &-card {
        width: 100%;
        height: auto;
        margin-bottom: 30px;
        padding: 30px 15px;
        background: #fff;
        border-radius: 30px;
        box-shadow: 0px 5px 30px rgba(0,0,0,0.05);
        &__header {
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: flex-start; }
        &__image {
            width: 60px;
            height: 60px;
            margin-right: 15px;
            img {} }

        &__info {}

        &__name {
            font-size: 18px;
            font-weight: 600; }
        &__description {
            font-size: 14px;
            font-weight: 500;
            opacity: .7; }
        &__stars {
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            ul {
                margin: 0;
                margin-right: 15px;
                padding: 0;
                list-style: none;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                li {
                    margin-right: 5px;
                    img {
                        width: 20px; } } }
            span {
                padding-top: 3px;
                font-size: 14px;
                font-weight: 500; } }
        p {
            line-height: 150%;
            opacity: .7; } } }
