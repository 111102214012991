.services {
    h2 {
        margin-bottom: 30px; }
    &-card {
        margin-bottom: 30px;
        padding: 15px;
        border-radius: 30px;
        background: #fff;
        transition: .3s;
        &:hover {
            box-shadow: 5px 5px 30px rgba(0,0,0,0.1); }
        &__image {
            width: 100%;
            height: 180px;
            margin-bottom: 25px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                border-radius: 20px; } }
        &__header {

            h4 {
                margin-bottom: 15px;
                font-size: 20px;
                font-weight: 600; }
            p {
                min-height: 120px;
                margin-bottom: 30px;
                line-height: 150%; } }
        &__footer {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            button {
                width: 100%;
                margin-bottom: 10px;
                border-radius: 15px;
                &:first-child {
                    min-height: 50px;
                    font-weight: 600; }
                &:last-child {
                    margin-bottom: 0px;
                    font-weight: 500;
                    transition: .3s;
                    opacity: .5;
                    &:hover {
                        opacity: 1; } } } } } }
