.header {
    width: 100%;
    height: auto;
    &__inner {
        width: 100%;
        min-height: 120px;
        display: flex;
        align-items: center;
        justify-content: space-between; }
    &__logo {
        font-size: 28px;
        font-weight: 700;
        img {
            width: 200px; } }
    &__nav {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            align-items: center;
            justify-content: center;
            li {
                margin-right: 30px;
                &:last-child {
                    margin-right: 45px; } } }
        &-item {
            font-size: 16px;
            font-weight: 600;
            text-decoration: none;
            color: #232323; } }
    &__btn {
        min-height: 50px;
        padding-left: 30px;
        padding-right: 30px;
        border-radius: 15px;
        font-weight: 500; } }
