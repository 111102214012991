.home {
    padding: 0;
    &__inner {
        position: relative;
        width: 100%;
        height: 600px;
        margin-bottom: 150px;
        padding: 0 60px;
        background: url('../img/home1_1.jpeg') no-repeat;
        background-size: cover;
        background-position: center;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        box-sizing: border-box;
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(to right, rgba(0,0,0,0.4), transparent);
            border-radius: 30px; } }
    &__content {
        position: relative;
        width: 50%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        box-sizing: border-box;
        z-index: 10;
        h1 {
            line-height: 150%;
            margin-bottom: 30px;
            color: #fff; }

        p {
            font-size: 16px;
            line-height: 200%;
            color: #fff; } }
    &-form {
        position: absolute;
        left: 0;
        bottom: -60px;
        width: 100%;
        box-sizing: border-box;
        z-index: 10;
        &__inner {
            width: 100%;
            min-height: 120px;
            padding: 30px 30px;
            background: #fff;
            border-radius: 30px;
            box-shadow: 0px 15px 30px rgba(0,0,0,0.1);
            display: flex;
            align-items: center;
            form {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 30px; } }
        &__item {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            label {
                margin: 0;
                margin-bottom: 5px;
                padding: 0;
                font-weight: 500;
                text-align: left; }
            input {
                width: 100%;
                height: 60px;
                padding-left: 15px;
                border-radius: 15px;
                border: none;
                background: rgba(0,0,0,0.05);
                color: #232323; }
            button {
                width: 100%;
                min-height: 60px;
                border-radius: 15px;
                border: none;
                font-size: 16px;
                font-weight: 600;
                background: #336AF5;
                color: #fff; } } }
    &-qualities {
        width: 100%;
        margin-bottom: 30px;
        padding-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 2px solid #336AF5;
        &__number {
            margin-right: 30px;
            padding-left: 30px;
            font-size: 40px; }
        &__description {
            width: 55%;
            padding-right: 30px;
            font-size: 17px;
            font-weight: 500;
            line-height: 150%;
            text-align: right;
            color: rgba(0,0,0,0.5); } } }
