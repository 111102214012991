* {
    outline: none !important; }

html, body {
    font-family: 'Gilroy', sans-serif;
    background: #fbfbfb; }

h1, h2, h3, h4, h5, h6, ul, p {
    margin: 0;
    padding: 0; }

h1 {
    font-size: 38px;
    font-weight: 700; }

h2 {
    font-size: 34px;
    font-weight: 600;
    line-height: 150%; }

p {
    font-size: 16px;
    line-height: 200%; }

button {
    outline: none !important;
    border: none !important;
    transform: scale(1);
    transition: .3s !important;
    &:active, &:focus, &:hover {
        outline: none !important;
        border: none !important; }
    &:active {
        outline: none !important;
        border: none !important;
        transform: scale(0.98); } }

section {
    padding: 60px 0; }

.img-notification {
    position: absolute;
    top: 0;
    left: 0;
    width: 70%;
    min-height: 120px;
    padding: 15px 30px;
    background: #fff;
    border-radius: 30px;
    box-shadow: 0px 5px 40px rgba(0,0,0,0.05);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &__icon {
        margin-right: 20px;
        img {
            width: 50px;
            height: 50px; } }
    &__text {
        line-height: 120%;
        font-weight: 700; } }
.in1 {
    top: 50%;
    left: 40%;
    z-index: 10; }
.in2 {
    top: calc(50% + 140px);
    left: 45%;
    z-index: 0; }

@media screen and (max-width: 768px) {
    .header__nav nav {
        display: none; }
    .home__content {
        width: 100%; }
    .in1, .in2 {
        left: 30px; } }
