.about {
    &__image {
        position: relative;
        width: 100%;
        height: 650px;
        border-radius: 30px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: 30px; } }
    &-content {
        padding: 30px 0;
        h2 {
            margin-bottom: 30px; }
        p {
            margin-bottom: 30px; }

        ul {
            margin: 0;
            margin-bottom: 45px;
            padding: 0;
            list-style: none;
            li {
                position: relative;
                margin-bottom: 15px;
                padding-left: 45px;
                display: flex;
                align-items: center;
                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    left: 0;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background: url('../img/svg/arrow.svg') no-repeat;
                    background-position: center;
                    background-size: contain; } } }
        button {
            min-height: 60px;
            padding-left: 30px;
            padding-right: 30px;
            background: #336AF5;
            border-radius: 15px;
            font-weight: 600; } } }
