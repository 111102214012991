@import "modules/header/header";

@import "modules/1_home/home.sass";
@import "modules/2_about/about.sass";
@import "modules/3_services/services.sass";
@import "modules/4_projects/projects.sass";
@import "modules/5_clients/clients.sass";
@import "modules/6_comments/comments.sass";
@import "modules/7_contacts/contacts.sass";
@import "modules/modals/modals.sass";

@import "modules/footer/footer";
