.clients {
    h2 {
        margin-bottom: 45px !important; }
    &-card {
        position: relative;
        width: 100%;
        height: auto;
        margin-bottom: 30px;
        border-radius: 20px;
        overflow: hidden;
        background: red;
        &__image {
            position: absolute;
            width: 100%;
            height: 100%;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center; } }
        &__content {
            position: relative;
            width: 100%;
            min-height: 400px;
            padding: 45px 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.7));
            z-index: 10; }
        &__name {
            font-size: 32px;
            font-weight: 700;
            color: #fff; }
        &__description {
            font-size: 20px;
            font-weight: 600;
            color: #fff; } } }
